html, body {
  height: 100%;
}

body {
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

#canvasGradient {
  background: linear-gradient(#020024 0%, #090979 35%, #00d4ff 100%);
}

/*# sourceMappingURL=index.368c7f29.css.map */
